import {environment} from "../config/environment";
import auth from '../auth/auth';
import axios from 'axios';

const request = (method, path, payload) => {
  return new Promise((resolve, reject) => {
    axios.request({
      method: method,
      baseURL: environment().apiURL,
      url: path,
      data: payload,
      headers: {
        'Authorization': `Bearer ${auth.getToken()}`,
        'X-Company-Id': auth.getActiveCompany(),
        'X-Financial-Year-Id': auth.getActiveFinancialYear()
      },
    }).then((response) => {
      resolve(response);
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        auth.logout()
      }

      reject(error)
    });
  });
}

export default request
