import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Decimal from 'decimal.js';
import Grid from '@mui/material/Unstable_Grid2'
import FormControl from '@mui/material/FormControl'
import AddBoxIcon from '@mui/icons-material/AddBox'
import ClearIcon from '@mui/icons-material/Clear'
import Paper from '@mui/material/Paper'
import dayjs from 'dayjs'
import SharedFields from './SharedFields'
import InvoiceRow from './InvoiceRow'
import SettingsDropdown from './SettingsDropdown'
import ImageUploader from '../../../components/ImageUploader'
import Loader from '../../../components/Loader'
import { validateForm } from '../utils/validate'
import { formatMoneyWithCurrency } from '../../../utils/money'
import {
  termsOfPaymentOptions,
  vatOptions,
  discountOptions,
} from '../../../utils/invoice'
import {
  createLogoAsync,
  removeLogoAsync,
  createInvoiceSettingsAsync,
  updateInvoiceSettingsAsync
} from '../invoicesSlice'
import { SketchPicker } from 'react-color';
import Email from './Email'

export default function Form(props) {
  const dispatch = useDispatch()
  const defaultInvoiceRow = {
    id: undefined,
    description: '',
    unit: '',
    quantity: '',
    amount: '',
    vat: { label: '25%', value: 0.25 },
    discount: { label: '', value: 0.0 },
    article: '',
    account: { label: 3001, value: 3001 }
  }

  const [selectedCustomer, setSelectedCustomer] = useState('')
  const [customerData, setCustomerData] = useState({})
  const [invoiceDate, setInvoiceDate] = useState(null)
  const [dueDate, setDueDate] = useState(null)
  const [termsOfPayment, setTermsOfPayment] = useState({ label: '30 dagar', value: '30' })
  const [customerOptions, setCustomerOptions] = useState([])
  const [accountOptions, setAccountOptions] = useState([])
  const [articleOptions, setArticleOptions] = useState([])
  const [unitOptions, setUnitOptions] = useState([])
  const [invoiceRows, setInvoiceRows] = useState([defaultInvoiceRow])
  const [removedInvoiceRows, setRemovedInvoiceRows] = useState([])
  const [vatAmount, setVatAmount] = useState(0)
  const [netAmount, setNetAmount] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const [roundOff, setRoundOff] = useState(0)
  const [ourReference, setOurReference] = useState('')
  const [yourReference, setYourReference] = useState('')
  const [invoiceErrors, setInvoiceErrors] = useState([])
  const [invoiceRowErrors, setInvoiceRowErrors] = useState({})
  const [uploadLogoModalOpen, setUploadLogoModalOpen] = useState(false)
  const [backgroundColor, setBackgroundColor] = useState('')
  const [editBackgroundColorOpen, setEditBackgroundColorOpen] = useState(false)
  const [textColor, setTextColor] = useState('')
  const [editTextColorOpen, setEditTextColorOpen] = useState(false)
  const [emailModalOpen, setEmailModalOpen] = useState(false)
  const [saveOpenAndPrintLoading, setSaveOpenAndPrintLoading] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [currency, setCurrency] = useState({label: 'SEK', value: 'SEK'})

  const resetForm = () => {
    setSelectedCustomer('')
    setCustomerData({})
    setCustomerOptions([])
    setInvoiceRows([defaultInvoiceRow])
    setYourReference('')
    setOurReference('')
  }

  const setNormalizeInvoiceData = useCallback((invoice, isCopy) => {
    setSelectedCustomer({
      label: invoice.customer.name,
      value: invoice.customer.id
    })
    setCustomerData(invoice.customer)
    setInvoiceDate(dayjs(invoice.invoice_date))
    setYourReference(invoice.your_reference || '')
    setOurReference(invoice.our_reference || '')
    setDueDate(dayjs(invoice.due_date))
    setTermsOfPayment({
      label: invoice.terms_of_payment.description,
      value: invoice.terms_of_payment.code
    })
    const normalizedRows = invoice.invoice_rows.map((row) => normalizeInvoiceRow(row))
    setInvoiceRows(normalizedRows)
  }, [])

  const normalizeInvoiceRow = (row, isCopy) => {
    const vat = vatOptions.find(opt => new Decimal(row.vat).equals(opt.value))
    const discount = discountOptions.find(opt => new Decimal(row.discount).equals(opt.value))
    let article = { label: '', value: '' }
    if (row.article) {
      article = { label: row.article.description, value: row.article.id }
    }

    return {
      id: isCopy ? undefined : row.id,
      description: row.description,
      unit: { label: row.unit.description, value: row.unit.id },
      quantity: row.quantity,
      amount: row.amount,
      vat: vat || { label: '25%', value: 0.25 },
      discount: discount || { label: '', value: 0.0 },
      article: article,
      account: { label: row.account.number, value: row.account.number }
    }
  }

  useEffect(() => {
    if (props.invoice) {
      setNormalizeInvoiceData(props.invoice, props.fromCopy)
    }
  }, [props.invoice, props.fromCopy, setNormalizeInvoiceData])

  useEffect(() => {
    if (!props.invoice && !invoiceDate) {
      setInvoiceDate(dayjs())
    }

    if (!props.invoice && !dueDate) {
      const currentDate = dayjs()
      const defaultDueDate = currentDate.add(30, "day");
      setDueDate(defaultDueDate)
    }
  }, [props.invoice, invoiceDate, dueDate])

  useEffect(() => {
    if (customerData.id !== selectedCustomer.value) {
      const data = props.customers.find((customer) => {
        return customer.id === selectedCustomer.value
      })

      setCustomerData(data)
      setCurrency({label: data.currency, value: data.currency})
    }
  }, [props.customers, customerData, selectedCustomer])

  useEffect(() => {
    let vatSum = 0
    let netSum = 0

    invoiceRows.forEach((row) => {
      if (row.amount && row.vat && row.quantity) {
        let amount = new Decimal(row.amount)
        if (row.discount.value > 0 || row.discount > 0) {
          amount = amount - (amount * (row.discount.value || row.discount))
        }

        let vat
        if (row.vat.value !== undefined) {
          vat = new Decimal(row.vat.value)
        } else {
          vat = new Decimal(row.vat || 0)
        }
        const quantity = new Decimal(row.quantity)

        vatSum += amount * vat * quantity
        netSum += amount * quantity
      }
    })

    const total = vatSum + netSum
    const rounded = Math.round(total)

    setVatAmount(vatSum)
    setNetAmount(netSum)
    setTotalAmount(rounded)
    setRoundOff((rounded - total).toFixed(2))
  }, [invoiceRows])

  useEffect(() => {
    const normalized = props.accounts.map((account) => {
      return {
        value: account.number,
        label: account.description,
      }
    })
    setAccountOptions(normalized)
  }, [props.accounts])

  useEffect(() => {
    const normalized = props.customers.map((customer) => {
      return {
        value: customer.id,
        label: customer.name,
      }
    })
    setCustomerOptions(normalized)
  }, [props.customers])

  useEffect(() => {
    const normalized = props.articles.map((article) => {
      return {
        value: article.id,
        label: article.description,
      }
    })
    setArticleOptions(normalized)
  }, [props.articles])

  useEffect(() => {
    const normalized = props.units.map((unit) => {
      return {
        value: unit.id,
        label: unit.description,
      }
    })
    setUnitOptions(normalized)
  }, [props.units])

  const saveAndEmail = async(e, email, ccs) => {
    const response = await onSubmit(e)
    if (response.meta.requestStatus === 'fulfilled') {
      await props.sendInvoice(response.payload, email, ccs)
    }
    setEmailModalOpen(false)
  }

  const saveAndOpenForPrint = async(e) => {
    setSaveOpenAndPrintLoading(true)
    const response = await onSubmit(e)
    if (response.meta.requestStatus === 'fulfilled') {
      await props.printInvoice(response.payload)
    }
    setSaveOpenAndPrintLoading(false)
  }

  const save = async(e) => {
    setSaveLoading(true)
    await onSubmit(e)
    setSaveLoading(false)
  }

  const onSubmit = async(e) => {
    e.preventDefault()

    const params = {
      invoice_date: invoiceDate.format('YYYY-MM-DD'),
      due_date: dueDate.format('YYYY-MM-DD'),
      terms_of_payment: termsOfPayment.value,
      currency: currency.value,
      customer_id: selectedCustomer.value,
      our_reference: ourReference,
      your_reference: yourReference,
      template: customerData.invoice_template,
      invoice_rows: invoiceRows.map((row) => {
        const invoiceRow = {
          description: row.description,
          unit_id: row.unit.value,
          quantity: new Decimal(row.quantity || 0),
          amount: new Decimal(row.amount || 0),
          vat: new Decimal(row.vat.value || 0),
          account_number: parseInt(row.account.value),
          discount: new Decimal(row.discount.value || 0)
        }

        if (row.id) {
          invoiceRow.id = row.id
        }

        if (row.article) {
          invoiceRow.article_id = row.article.value
        }

        return invoiceRow
      })
    }

    const [errors, rowErrors] = validateForm(params, props.activeFinancialYear)
    setInvoiceErrors(errors)
    setInvoiceRowErrors(rowErrors)

    if (errors.length > 0 || Object.keys(rowErrors).length > 0) {
      return
    }

    if (props.creditedInvoice) {
      params.invoice_id = props.invoice.id
      params.credit = true
    }

    let response;

    if (props.invoice && !props.fromCopy && !props.creditedInvoice) {
      removedInvoiceRows.forEach(row => {
         params.invoice_rows.push({id: row.id, removed: true})
      })

      params.id = props.invoice.id
      response = await props.updateInvoice(params)
    } else {
      response = await props.createInvoice(params)
    }

    if (response.meta.requestStatus === 'fulfilled') {
      resetForm()
    } else {
      // TODO: Present error message.
      console.log("error creating invoice")
    }

    return response
  }

  const updateTermsOfPayment = (termsOfPayment) => {
    const date = invoiceDate
    const dueDate = date.add(termsOfPayment.value, "day")
    setDueDate(dueDate)
    setTermsOfPayment(termsOfPayment)
  }

  const addInvoiceRow = (e) => {
    e.preventDefault()

    const rows = [...invoiceRows]
    rows.push(defaultInvoiceRow)
    setInvoiceRows(rows);
  }

  const removeInvoiceRow = (index) => {
    // TODO: Are you sure prompt if row has been edited (any values)?
    const rows = [...invoiceRows]
    const newRows = rows.filter((_, i) => i !== index);

    const removedRow = rows[index]
    if (removedRow.id) {
      setRemovedInvoiceRows([...removedInvoiceRows, removedRow])
    }

    setInvoiceRows(newRows)
  }

  const updateInvoiceRow = (row, index) => {
    const rows = [...invoiceRows]
    rows[index] = row
    setInvoiceRows(rows)
  }

  const selectArticle = (article, row, index) => {
    const selectedArticle = props.articles.find(art => art.id === parseInt(article.value))
    const unit = props.units.find(u => u.id === selectedArticle.unit_id)
    row.unit = {
      label: unit.description,
      value: unit.id
    }

    const vat = vatOptions.find(opt => new Decimal(selectedArticle.vat).equals(opt.value))
    row.vat = vat
    row.amount = new Decimal(selectedArticle.sales_price)
    row.description = selectedArticle.description
    row.account = {
      label: selectedArticle.sales_account,
      value: selectedArticle.sales_account
    }

    updateInvoiceRow(row, index)
  }

  const createCustomer = async(params) => {
    const response = await props.createCustomer(params)
    setSelectedCustomer({
      label: response.name,
      value: response.id
    })
    setCustomerData(response)
    setCurrency({label: response.currency, value: response.currency})
  }

  const updateCustomer = async(params) => {
    const response = await props.updateCustomer(params)
    setSelectedCustomer({
      label: response.name,
      value: response.id
    })
    setCustomerData(response)
    setCurrency({label: response.currency, value: response.currency})
  }

  const onLogoUpload = async(file) => {
    const formData = new FormData();
    formData.append('logo', file);
    if (props.activeCompany.invoice_settings?.id) {
      formData.append('invoice_setting_id', props.activeCompany.invoice_settings?.id);
    }

    const response = await dispatch(createLogoAsync(formData))

    if (response.type === 'invoices/createLogo/fulfilled') {
      setUploadLogoModalOpen(false)
    }
  }

  const toggleEditBackgroundColorOpen = () => {
    setEditTextColorOpen(false)
    setEditBackgroundColorOpen(!editBackgroundColorOpen)
  }

  const toggleEditTextColorOpen = () => {
    setEditBackgroundColorOpen(false)
    setEditTextColorOpen(!editTextColorOpen)
  }

  const saveColors = async () => {
    if (!textColor && !backgroundColor) {
      return
    }

    const params = {}
    if (textColor) {
      params.text_color = textColor
    }
    if (backgroundColor) {
      params.background_color = backgroundColor
    }

    if (props.activeCompany.invoice_settings?.id) {
      params.id = props.activeCompany.invoice_settings.id
      const response = await dispatch(updateInvoiceSettingsAsync(params))
      if (response.type === 'invoices/updateInvoiceSettings/fulfilled') {
        setEditBackgroundColorOpen(false)
        setEditTextColorOpen(false)
      }
    } else {
      const response = await dispatch(createInvoiceSettingsAsync(params))
      if (response.type === 'invoices/createInvoiceSettings/fulfilled') {
        setEditBackgroundColorOpen(false)
        setEditTextColorOpen(false)
      }
    }

    setTextColor('')
    setBackgroundColor('')
  }

  const cancelColorChange = () => {
    setTextColor('')
    setBackgroundColor('')
    setEditBackgroundColorOpen(false)
    setEditTextColorOpen(false)
  }

  return (
    <Grid container justify="flex-start" spacing={2} style={{margin: 0}}>
      <Grid
        item
        xs={12}
        style={{
          background: backgroundColor || props.activeCompany.invoice_settings?.background_color || '#0284C7',
          color: textColor || props.activeCompany.invoice_settings?.text_color || '#FFFFFF'
        }}
      >
        <Grid container spacing={4} sx={{height: '200px'}}>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Grid container>
              <Grid item xs={8} sx={{height: '140px'}}>
                {props.activeCompany.invoice_settings?.logo_url ?
                  <div
                    style={{
                      width: '100%',
                      overflow: 'hidden',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: "120px",
                        maxWidth: "300px"
                      }}
                      src={props.activeCompany.invoice_settings.logo_url}
                      alt="invoice logo"
                    />
                  </div>
                :
                  <h1 className="text-3xl p-2">{props.activeCompany.name}</h1>
                }
              </Grid>
              <Grid item xs={12} sx={{margin: 0, padding: '5px 5px 0 15px'}}>
                 <SettingsDropdown
                  logoUrl={props.activeCompany.invoice_settings?.logo_url}
                  removeLogo={() => dispatch(removeLogoAsync(props.activeCompany.invoice_settings?.id))}
                  openLogoUpload={() => setUploadLogoModalOpen(true)}
                  openEditBackgroundColor={() => toggleEditBackgroundColorOpen()}
                  openEditTextColor={() => toggleEditTextColorOpen()}
                />
                {(backgroundColor || textColor) &&
                  <>
                    <button
                      className="bg-emerald-500 text-white p-2 cursor-pointer ml-1"
                      onClick={saveColors}
                      style={{fontSize: '14px'}}
                    >
                      Spara inställningar
                    </button>
                    <button
                      className="bg-emerald-500 text-white p-2 cursor-pointer ml-1"
                      onClick={cancelColorChange}
                      style={{fontSize: '14px'}}
                    >
                      Avbryt
                    </button>
                  </>
                }
              </Grid>
              {editBackgroundColorOpen &&
                <Grid
                  item
                  xs={4}
                  style={{
                    width: 'auto',
                    left: '50%',
                    top: '7%',
                    position: 'absolute',
                    zIndex: 9998
                  }}
                >
                  <Paper
                    sx={{
                      pt: 1.5,
                      pl: 2.5,
                      pr: 2.5,
                      pb: 2.5,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <p>Ändra bakgrundsfärg</p>
                    <ClearIcon
                      onClick={() => setEditBackgroundColorOpen(false)}
                      style={{
                        position: 'absolute',
                        right: '8.5%',
                        top: '7%',
                        cursor: 'pointer'
                      }}
                    />
                    <SketchPicker
                      color={backgroundColor}
                      onChangeComplete={(color) => setBackgroundColor(color.hex)}
                    />
                  </Paper>
                </Grid>
              }
              {editTextColorOpen &&
                <Grid
                  item
                  xs={4}
                  style={{
                    width: 'auto',
                    left: '50%',
                    top: '7%',
                    position: 'absolute',
                    zIndex: 9998
                  }}
                >
                  <Paper
                    sx={{
                      pt: 1.5,
                      pl: 2.5,
                      pr: 2.5,
                      pb: 2.5,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <p>Ändra textfärg</p>
                    <ClearIcon
                      onClick={() => setEditTextColorOpen(false)}
                      style={{
                        position: 'absolute',
                        right: '8.5%',
                        top: '7%',
                        cursor: 'pointer'
                      }}
                    />
                    <SketchPicker
                      color={textColor}
                      onChangeComplete={(color) => setTextColor(color.hex)}
                    />
                  </Paper>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Grid container>
              <Grid item xs={12} className="text-right">
                <h1 className="text-3xl p-2">Faktura</h1>
              </Grid>
              <Grid item xs={12} className="text-right">
                <h1 className="text-base px-2">{customerData.name}</h1>
                <h1 className="text-base px-2">{customerData.address}</h1>
                <h1 className="text-base px-2">{customerData.zip_code} {customerData.city}</h1>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="bg-slate-200 text-black">
        <Grid container>
          <Grid item xs={12}>
            <SharedFields
              activeCompany={props.activeCompany}
              termsOfPaymentOptions={termsOfPaymentOptions}
              customerData={customerData}
              customerOptions={customerOptions}
              searchCustomers={props.searchCustomers}
              selectCustomer={setSelectedCustomer}
              customer={selectedCustomer}
              updateInvoiceDate={setInvoiceDate}
              ourReference={ourReference}
              updateOurReference={setOurReference}
              yourReference={yourReference}
              updateYourReference={setYourReference}
              invoiceDate={invoiceDate}
              updateDueDate={setDueDate}
              dueDate={dueDate}
              updateTermsOfPayment={updateTermsOfPayment}
              termsOfPayment={termsOfPayment}
              createCustomer={createCustomer}
              updateCustomer={updateCustomer}
              customerFormOpen={props.customerFormOpen}
              toggleCustomerForm={props.toggleCustomerForm}
              invoiceErrors={invoiceErrors}
              currency={currency}
              updateCurrency={setCurrency}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              background: backgroundColor || props.activeCompany.invoice_settings?.background_color || '#0284C7',
              color: textColor || props.activeCompany.invoice_settings?.text_color || '#FFFFFF'
            }}
          >
            <Grid container>
              <Grid item xs={12} sm={12} md={2} lg={1.25}>
                Artikel
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={3}>
                Beskrivning
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={1.1}>
                Enhet
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={1.1}>
                Antal
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={1.1}>
                Pris
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={1.1}>
                Moms
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={1.1}>
                Rabatt
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={1.1}>
                Konto
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {invoiceRows.map((row, i) => (
              <InvoiceRow
                key={i}
                index={i}
                row={row}
                addInvoiceRow={addInvoiceRow}
                removeInvoiceRow={removeInvoiceRow}
                updateInvoiceRow={updateInvoiceRow}
                discountOptions={discountOptions}
                unitOptions={unitOptions}
                vatOptions={vatOptions}
                accountOptions={accountOptions}
                searchAccounts={props.searchAccounts}
                articleOptions={articleOptions}
                selectArticle={selectArticle}
                createArticle={props.createArticle}
                errors={invoiceRowErrors[i] || []}
              />
            ))}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={2}>
            <AddBoxIcon
              className="text-emerald-500"
              style={{cursor: 'pointer', margin: '-10px 0 0 3px', fontSize: '36px'}}
              onClick={(e) => addInvoiceRow(e)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              background: backgroundColor || props.activeCompany.invoice_settings?.background_color || '#0284C7',
              color: textColor || props.activeCompany.invoice_settings?.text_color || '#FFFFFF'
            }}
          >
            <Grid container>
              <Grid item xs={12} sm={12} md={4} lg={2}>
                <p>Netto</p>
                <p>{formatMoneyWithCurrency(netAmount, currency.label)}</p>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={2}>
                <p>Moms</p>
                <p>{formatMoneyWithCurrency(vatAmount, currency.label)}</p>
              </Grid>
              {roundOff !== 0 &&
                <Grid item xs={12} sm={12} md={4} lg={2}>
                  <p>Öresavrundning</p>
                  <p>{formatMoneyWithCurrency(roundOff, currency.label)}</p>
                </Grid>
              }
              <Grid item xs={12} sm={12} md={4} lg={2}>
                <p>Totalt</p>
                <p>{formatMoneyWithCurrency(totalAmount, currency.label)}</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {invoiceErrors.includes('incorrect_financial_year') &&
        <Grid item xs={12}>
          <p>Fakturadatum ligger utanför aktuellt räkenskapsår. Vänligen välj korrekt räkenskapsår.</p>
        </Grid>
      }
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={3} sx={{padding: '10px 0'}}>
            <FormControl fullWidth sx={{padding: '0 5px 0 0'}}>
              <button
                style={{maxHeight: '56px'}}
                onClick={(e) => save(e)}
                className="bg-emerald-500 text-white py-4 cursor-pointer"
              >
                { saveLoading ?
                  <Loader size={25} style={{color: '#fff'}}/>
                  :
                  "Spara"
                }
              </button>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} sx={{padding: '10px 0'}}>
            <FormControl fullWidth sx={{padding: '0 5px 0 0'}}>
              <button
                style={{maxHeight: '56px'}}
                onClick={() => setEmailModalOpen(true)}
                className="bg-emerald-500 text-white py-4 cursor-pointer"
              >
                Spara & Skicka med E-post
              </button>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} sx={{padding: '10px 0'}}>
            <FormControl fullWidth>
              <button
                style={{maxHeight: '56px'}}
                onClick={(e) => saveAndOpenForPrint(e)}
                className="bg-emerald-500 text-white py-4 cursor-pointer"
              >
                { saveOpenAndPrintLoading ?
                  <Loader size={25} style={{color: '#fff'}}/>
                  :
                  "Spara & Öppna för utskrift"
                }
              </button>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <ImageUploader
        title="Ladda upp logo"
        onClose={() => setUploadLogoModalOpen(false)}
        onUpload={onLogoUpload}
        open={uploadLogoModalOpen}
      />
      <Email
        close={() => setEmailModalOpen(false)}
        invoiceEmail={customerData?.email}
        onSave={(e, email, ccs) => saveAndEmail(e, email, ccs)}
        open={emailModalOpen}
      />
    </Grid>
  )
}
