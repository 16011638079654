import React, { useState } from 'react'
import { useParams } from "react-router-dom"
import i18n from '../../i18n'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { Navigate } from "react-router-dom"
import auth from '../../auth/auth'
import { updatePassword } from './forgotPasswordAPI'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.ekonomimolnet.se/">
        EkonomiMolnet Sverige AB
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const ForgotPassword = (props) => {
  const t = i18n.getFixedT()
  const { token } = useParams()
  const [password, setPassword] = useState('')
  const [passwordRepeated, setPasswordRepeated] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const [apiError, setApiError] = useState(false)

  const handleSubmit = async(e) => {
    e.preventDefault()

    setApiError(false)
    if (password !== passwordRepeated) {
      setPasswordError(true)
      return
    } else {
      setPasswordError(false)
    }

    const resp = await updatePassword(token, password)
    console.log("response: ", resp)
    if (resp.status === 200) {
      window.location.href = '/login';
    } else {
      setApiError(true)
    }
  }

  if (auth.isAuthenticated()){
    return (
      <Navigate to="/" replace />
    )
  }

  const onUpdatePassword = (value) => {
    setPasswordError(false)
    setPassword(value)
  }

  const onUpdatePasswordRepeated = (value) => {
    setPasswordError(false)
    setPasswordRepeated(value)
  }

  return (
    <Grid container className="bg-sky-500" style={{height: '100vh', width: '100%'}}>
      <Container component="main" maxWidth="xs">
        <h2 style={{color: '#ffffff', fontSize: '40px', marginTop: '15px'}}>EkonomiMolnet</h2>
        <CssBaseline />
        <Box
          className="bg-white"
          sx={{
            marginTop: '30px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '50px'
          }}
        >
          <Avatar sx={{ m: 1 }} style={{background: 'rgba(239, 68, 68, 1)'}}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Skapa nytt lösenord
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              value={password}
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('password')}
              type="password"
              id="password"
              autoComplete="new-password"
              onChange={(e) => onUpdatePassword(e.target.value)}
            />
            <TextField
              value={passwordRepeated}
              margin="normal"
              required
              fullWidth
              name="passwordRepeated"
              label="Repetera lösenord"
              type="password"
              id="passwordRepeated"
              autoComplete="new-password"
              onChange={(e) => onUpdatePasswordRepeated(e.target.value)}
            />
            {passwordError &&
              <p>Lösenorden måste matcha.</p>
            }
            {apiError &&
              <p>Något gick fel. Vänligen försök igen eller kontakta kundtjänst.</p>
            }
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{background: 'rgba(16, 185, 129, 1)'}}
              sx={{ mt: 3, mb: 2 }}
            >
              Skapa nytt lösenord
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} style={{color: '#ffffff'}}/>
      </Container>
    </Grid>
  )
}

export default ForgotPassword
