import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Checkbox from '@mui/material/Checkbox'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import StyledTextField from '../../../components/StyledTextField'
import ClearIcon from '@mui/icons-material/Clear'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import AsyncSelect from 'react-select/async'
import Select from '@mui/material/Select'
import i18n from '../../../i18n'
import { fetchCountries } from '../invoicesAPI'
import debounce from '../../../utils/debounce'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30vw',
  height: '72vh',
  overflowX: 'scroll',
  bgcolor: 'background.paper',
  border: '1px solid #ccc',
  boxShadow: 24,
  p: 4,
};

const requiredFields = ['name']

export default function CustomerForm(props) {
  const { activeCompany, open, close, onSave, customer } = props;

  const defaultCountry = {
    label: i18n.language === 'sv' ? 'Sverige' : 'Sweden',
    value: i18n.language === 'sv' ? 'SE' : 'EN',
    data: {},
  }

  const [errors, setErrors] = useState([])
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [isPrivate, setIsPrivate] = useState(false)
  const [corporateIdentityNumber, setCorporateIdentityNumber] = useState('')
  const [vatIdentificationNumber, setVatIdentificationNumber] = useState('')
  const [foreign, setForeign] = useState(false)
  const [invoiceTemplate, setInvoiceTemplate] = useState('')
  const [country, setCountry] = useState('')
  const [currency, setCurrency] = useState('SEK')

  useEffect(() => {
    if (customer.id) {
      setId(customer.id)
      setName(customer.name)
      setAddress(customer.address)
      setAddress2(customer.address2)
      setCity(customer.city)
      setEmail(customer.email)
      setPhoneNumber(customer.phone)
      setZipCode(customer.zip_code)
      setIsPrivate(customer.private)
      setForeign(customer.foreign)
      setInvoiceTemplate(customer.invoice_template || activeCompany.default_invoice_template)
      setCorporateIdentityNumber(customer.corporate_identity_number)
      setVatIdentificationNumber(customer.vat_identification_number)
      setCountry({
        value: customer.country.alpha2,
        label: customer.country.name[i18n.language],
        data: customer.country
      })
      setCurrency(customer.currency)
    } else {
      setId('')
      setName('')
      setAddress('')
      setAddress2('')
      setCity('')
      setEmail('')
      setPhoneNumber('')
      setZipCode('')
      setIsPrivate(false)
      setForeign(false)
      setInvoiceTemplate(activeCompany.default_invoice_template || '')
      setCorporateIdentityNumber('')
      setVatIdentificationNumber('')
      setCountry('')
      setCurrency('SEK')
    }
  }, [customer])

  const onSubmit = () => {
    const params = {
      name: name,
      address: address,
      address2: address2,
      city: city,
      country_alpha2: country?.value,
      email: email,
      phone: phoneNumber,
      private: isPrivate,
      zip_code: zipCode,
      corporate_identity_number: corporateIdentityNumber,
      vat_identification_number: vatIdentificationNumber,
      invoice_template: invoiceTemplate,
      currency: currency
    }

    const errors = []
    Object.entries(params).forEach(([key, value]) => {
      if (requiredFields.includes(key) && value === '') {
        errors.push(key)
      }
    })

    if (foreign && !vatIdentificationNumber) {
      errors.push('foreign_missing_vat_nr')
    }

    if (errors.length > 0) {
      setErrors(errors)
      return
    }

    if (id) {
      params.id = id
    }

    onSave(params)
  }

  const searchCountries = debounce(async (input, callback) => {
    if (!input) {
      return callback([]);
    }

    const response = await fetchCountries(input)
    let options = []
    if (response.status === 200) {
      options = response.data.map((country) => {
        return {
          value: country.alpha2,
          label: country.name[i18n.language],
          data: country
        }
      })
    }

    return callback(options)
  }, 600);

  const selectCountry = (country) => {
    setCountry(country)
    setForeign(!!country && country.value !== 'SE')
    setInvoiceTemplate(!country || country?.value === 'SE' ? 'default' : 'english')
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{backgroundColor: 'none'}}
    >
      <Box sx={style}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={10} lg={10}>
            <h1 style={{fontSize: '20px'}}>{id ? 'Redigera kund' : 'Skapa kund'}</h1>
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <ClearIcon
              onClick={close}
              sx={{
                float: 'right',
                margin: '-20px -20px 0',
                cursor: 'pointer'
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <StyledTextField
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                size='small'
                fontSize='13px'
                placeholder="Namn"
                error={errors.includes('name')}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <StyledTextField
                id="corporateIdentityNumber"
                value={corporateIdentityNumber}
                onChange={(e) => setCorporateIdentityNumber(e.target.value)}
                size='small'
                fontSize='13px'
                placeholder="Orgnummer/Personnummer"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <StyledTextField
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                size='small'
                fontSize='13px'
                placeholder="E-postadress"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <StyledTextField
                id="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                size='small'
                fontSize='13px'
                placeholder="Telefonnummer"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <StyledTextField
                id="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                size='small'
                fontSize='13px'
                placeholder="Adress"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <StyledTextField
                id="Address2"
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
                size='small'
                fontSize='13px'
                placeholder="Adress 2"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <StyledTextField
                id="zipCode"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                size='small'
                fontSize='13px'
                placeholder="Postnummer"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <StyledTextField
                id="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                size='small'
                fontSize='13px'
                placeholder="Ort"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <AsyncSelect
                placeholder="Land"
                loadOptions={searchCountries}
                onChange={(value) => selectCountry(value)}
                noOptionsMessage={() => "Fyll i textfältet för att söka efter land"}
                loadingMessage={() => "Laddar..."}
                isClearable
                type="input"
                value={country}
                styles={{
                  menu: (provided, state) => ({
                    ...provided,
                    cursor: 'pointer',
                    zIndex: 999,
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    cursor: 'text'
                  }),
                }}
              />
            </FormControl>
          </Grid>
          { foreign &&
            <Grid item xs={12}>
              <FormControl fullWidth>
                <StyledTextField
                  id="vatIdentificationNumber"
                  value={vatIdentificationNumber}
                  onChange={(e) => setVatIdentificationNumber(e.target.value)}
                  size='small'
                  fontSize='13px'
                  placeholder="VAT-nummer"
                  error={errors.includes('foreign_missing_vat_nr')}
                />
              </FormControl>
            </Grid>
          }
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Valuta</InputLabel>
              <Select
                value={currency}
                label="Faktura-mall"
                onChange={(e) => setCurrency(e.target.value)}
              >
                <MenuItem style={{color: '#000'}} value="SEK">SEK</MenuItem>
                <MenuItem style={{color: '#000'}} value="EUR">EUR</MenuItem>
                <MenuItem style={{color: '#000'}} value="USD">USD</MenuItem>
                <MenuItem style={{color: '#000'}} value="NOK">NOK</MenuItem>
                <MenuItem style={{color: '#000'}} value="DKK">DKK</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Faktura-mall</InputLabel>
              <Select
                value={invoiceTemplate}
                label="Faktura-mall"
                onChange={(e) => setInvoiceTemplate(e.target.value)}
              >
                <MenuItem style={{color: '#000'}} value="default">Standard (SE)</MenuItem>
                <MenuItem style={{color: '#000'}} value="english">Engelska</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label="Privatkund"
              sx={{
                '& .MuiTypography-root': {
                  fontSize: '13px'
                },
              }}
              control={
                <Checkbox
                  checked={isPrivate}
                  onChange={() => setIsPrivate(!isPrivate)}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <button
              onClick={onSubmit}
              className="bg-emerald-500 text-white py-4 px-8 cursor-pointer"
            >
              Spara
            </button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}
