import React, { useState, createRef } from "react";
import { createPopper } from "@popperjs/core";
import ClickAwayListener from '@mui/base/ClickAwayListener';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Loader from '../../../components/Loader';

const InvoiceActionsDropdown = (props) => {
  const {
    invoice,
    editInvoice,
    copyInvoice,
    creditInvoice,
    printInvoice,
    cancelInvoice,
    openEmailModal
  } = props;

  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const [printInvoiceLoading, setPrintInvoiceLoading] = useState(false)
  const btnDropdownRef = createRef();
  const popoverDropdownRef = createRef();

  const openDropdownPopover = () => {
    setDropdownPopoverShow(true);

    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "left-start",
    });
  };

  const closeDropdownPopover = () => {
    if (!printInvoiceLoading) {
      setDropdownPopoverShow(false);
    }
  };

  const print = async(e, invoice) => {
    e.preventDefault()
    setPrintInvoiceLoading(true)
    await printInvoice(invoice)
    setPrintInvoiceLoading(false)
  }

  return (
    <>
    <ClickAwayListener onClickAway={() => closeDropdownPopover()}>
      <a
        href="#"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
        style={{cursor: 'pointer'}}
      >
        <MoreHorizIcon sx={{cursor: 'pointer'}}/>
      </a>
    </ClickAwayListener>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
        <button
          className={
            "text-left text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700"
          }
          onClick={openEmailModal}
          style={{cursor: 'pointer'}}
        >
          Skicka
        </button>
        <button
          className={
            "text-left text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700"
          }
          onClick={(e) => print(e, invoice)}
          style={{cursor: 'pointer'}}
        >
          {printInvoiceLoading ? <Loader size={25} style={{margin: 0, padding: 0}}/> : "Skriv ut" }
        </button>
        {!invoice.booked &&
          <button
            className={
              "text-left text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700"
            }
            onClick={() => editInvoice(invoice)}
            style={{cursor: 'pointer'}}
          >
            Redigera
          </button>
        }
        <button
          className={
            "text-left text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700"
          }
          onClick={() => copyInvoice(invoice)}
          style={{cursor: 'pointer'}}
        >
          Kopiera
        </button>
        {!invoice.credit &&
          <button
            className={
              "text-left text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700"
            }
            onClick={() => creditInvoice(invoice)}
            style={{cursor: 'pointer'}}
          >
            Kreditera
          </button>
        }
        {!invoice.booked &&
          <button
            className={
              "text-left text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700"
            }
            onClick={(e) => cancelInvoice(invoice)}
            style={{cursor: 'pointer'}}
          >
            Ta bort
          </button>
        }
      </div>
    </>
  );
};

export default InvoiceActionsDropdown;
