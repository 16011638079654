import request from '../../services/request'
import fileUpload from '../../services/fileUpload'

export const fetchActiveCompany = () => {
  return request('get', 'employees/active_company')
}

export const fetchCustomers = () => {
  return request('get', 'customers')
}

export const searchCustomers = (name) => {
  return request('get', `customers?query=${name}`)
}

export const searchAccounts = (query) => {
  return request('get', `accounts?query=${query}`)
}

export const fetchArticles = () => {
  return request('get', 'articles')
}

export const fetchTermsOfPayment = () => {
  return request('get', 'terms_of_payment')
}

export const fetchUnits = () => {
  return request('get', 'units')
}

export const createInvoice = (params) => {
  return request('post', 'invoices', params)
}

export const updateInvoice = (params) => {
  return request('put', `invoices/${params.id}`, params)
}

export const removeInvoice = (invoiceId) => {
  return request('delete', `invoices/${invoiceId}`)
}

export const fetchInvoices = (page) => {
  return request('get', `invoices?page=${page}`)
}

export const getInvoice = (invoiceId) => {
  return request('get', `invoices/${invoiceId}`)
}

export const generateInvoicePDF = (invoiceId) => {
  return request('post', `pdf/invoice/${invoiceId}`)
}

export const sendInvoice = (invoiceId, email, ccs) => {
  return request('post', `invoices/${invoiceId}/send_email`, {
    recipient: email,
    ccs: ccs
  })
}

export const createArticle = (params) => {
  return request('post', 'articles', params)
}

export const createCustomer = (params) => {
  return request('post', 'customers', params)
}

export const updateCustomer = (params) => {
  return request('put', `customers/${params.id}`, params)
}

export const createLogo = async (formData) => {
  return new Promise((resolve, reject) => {
    fileUpload('invoice_settings/upload_logo', formData)
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            resolve({ data: data })
          }).catch(err => reject(err))
        } else {
          reject({ error: 'Error creating logo', http_status: response.status })
        }
      })
      .catch(error => reject(error));
  });
}

export const createInvoiceSettings = async (params) => {
  return request('post', `invoice_settings`, params)
}

export const updateInvoiceSettings = async (params) => {
  return request('put', `invoice_settings/${params.id}`, params)
}

export const removeLogo = async (invoiceSettingId) => {
  return request('put', `invoice_settings/${invoiceSettingId}/remove_logo`)
}

export const fetchCountries = (query) => {
  return request('get', `countries?query=${query}`)
}
