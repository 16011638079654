import React, { useState } from 'react'
import dayjs from 'dayjs';
import Select, { components } from 'react-select'
import Grid from '@mui/material/Unstable_Grid2'
import FormControl from '@mui/material/FormControl';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import i18n from '../../../i18n'
import StyledDatePicker from '../../../components/StyledDatePicker'
import StyledTextField from '../../../components/StyledTextField'
import AddBoxIcon from '@mui/icons-material/AddBox'
import CustomerForm from './CustomerForm'
import EditIcon from '@mui/icons-material/Edit';
import {
  selectCustomerStyles,
  selectTermsOfPaymentsStyles,
} from './SelectStyles';

export default function SharedFields(props) {
  const [editCustomer, setEditCustomer] = useState(false)

  const t = i18n.getFixedT()
  const {
    activeCompany,
    customerData,
    customerOptions,
    searchCustomers,
    selectCustomer,
    customer,
    updateInvoiceDate,
    invoiceDate,
    updateDueDate,
    dueDate,
    termsOfPaymentOptions,
    updateTermsOfPayment,
    termsOfPayment,
    ourReference,
    updateOurReference,
    yourReference,
    updateYourReference,
    createCustomer,
    updateCustomer,
    customerFormOpen,
    toggleCustomerForm,
    invoiceErrors,
    currency,
    updateCurrency,
  } = props;

  const onEditCustomer = () => {
    setEditCustomer(true)
    toggleCustomerForm()
  }

  const onCreateCustomer = () => {
    setEditCustomer(false)
    toggleCustomerForm()
  }

  const customerOption = ({ value, label }) => (
    <div>
      {label}
      <EditIcon
        onClick={() => onEditCustomer()}
        style={{
          cursor: 'pointer',
          fontSize: '20px',
          float: 'right',
          margin: '0 5px 0 0',
          color: '#696969'
        }}
      />
    </div>
  )

  const SingleValue = ({ ...props }) => {
    return (
      <components.SingleValue {...props}>{props.data.label}</components.SingleValue>
    )
  }

  return (
    <Grid container justify="flex-start" spacing={2}>
      <Grid item xs={12} sm={12} md={3} lg={2.2}>
        <span style={{float: 'left', fontSize: '13px'}}>{t('customer')}</span>
        <FormControl fullWidth style={{marginTop: '1px'}}>
          <Select
            components={{ SingleValue }}
            placeholder=""
            options={customerOptions}
            onKeyDown={(e) => searchCustomers(e.target.value)}
            onChange={(value) => selectCustomer(value)}
            formatOptionLabel={customerOption}
            value={customer}
            styles={selectCustomerStyles(invoiceErrors.includes('customer_id'))}
          />
        </FormControl>
      </Grid>
      <Grid item xs={0.4}>
        <AddBoxIcon
          className="text-emerald-500"
          style={{cursor: 'pointer', fontSize: '56px', margin: '15px 0 0 -20px'}}
          onClick={() => onCreateCustomer()}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={1.15}>
        <span style={{float: 'left', fontSize: '13px'}}>{t('currency')}</span>
        <FormControl fullWidth style={{marginTop: '1px'}}>
          <Select
            placeholder="Valuta"
            options={[
              {label: 'SEK', value: 'SEK'},
              {label: 'EUR', value: 'EUR'},
              {label: 'USD', value: 'USD'},
              {label: 'NOK', value: 'NOK'},
              {label: 'DKK', value: 'DKK'}
            ]}
            value={currency}
            onChange={(value) => updateCurrency(value)}
            styles={selectTermsOfPaymentsStyles(invoiceErrors.includes('currency'))}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={1.25}>
        <span style={{float: 'left', fontSize: '13px'}}>{t('termsOfPayment')}</span>
        <FormControl fullWidth style={{marginTop: '1px'}}>
          <Select
            placeholder="Betalningsvillkor"
            options={termsOfPaymentOptions}
            isSearchable={false}
            value={termsOfPayment}
            onChange={(value) => updateTermsOfPayment(value)}
            styles={selectTermsOfPaymentsStyles(invoiceErrors.includes('terms_of_payment'))} // TODO: Change false to validation error
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={1.3}>
        <span style={{float: 'left', fontSize: '13px'}}>{t('invoiceDate')}</span>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StyledDatePicker
            onChange={(value) => updateInvoiceDate(value)}
            value={dayjs(invoiceDate)}
            format="YYYY-MM-DD"
            slotProps={{ textField: { size: 'small' } }}
            fontSize='13px'
            height='48px'
            error={invoiceErrors.includes('invoice_date')}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={1.3}>
        <span style={{float: 'left', fontSize: '13px'}}>{t('dueDate')}</span>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StyledDatePicker
            onChange={(value) => updateDueDate(value)}
            value={dayjs(dueDate)}
            format="YYYY-MM-DD"
            slotProps={{ textField: { size: 'small' } }}
            fontSize='13px'
            height='48px'
            error={invoiceErrors.includes('due_date')}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={2.2}>
        <span style={{float: 'left', fontSize: '13px'}}>Vår referens</span>
        <FormControl fullWidth>
          <StyledTextField
            value={ourReference}
            onChange={(e) => updateOurReference(e.target.value)}
            size='small'
            fontSize='13px'
            height='31px'
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={2.2}>
        <span style={{float: 'left', fontSize: '13px'}}>Er referens</span>
        <FormControl fullWidth>
          <StyledTextField
            value={yourReference}
            onChange={(e) => updateYourReference(e.target.value)}
            size='small'
            fontSize='13px'
            height='31px'
          />
        </FormControl>
      </Grid>
      <CustomerForm
        activeCompany={activeCompany}
        customer={editCustomer ? customerData : {}}
        close={toggleCustomerForm}
        onSave={editCustomer ? updateCustomer : createCustomer}
        open={customerFormOpen}
      />
    </Grid>
  )
}
